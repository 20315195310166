<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            type="user" 
            label="교육대상자" 
            name="userId" 
            v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="JOB_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="jobCd"
            label="직책"
            v-model="searchParam.jobCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="eduSeniorItems"
            type="search"
            itemText="seniorName"
            itemValue="seniorId"
            label="법규선해임"
            name="seniorId"
            v-model="searchParam.seniorId">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="교육 대상자 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :usePaging="false"
      cardClass="topcolor-orange"
      rowKey="userId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'education-target-user-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            align: "center",
            sortable: true,
          },
          {
            name: "deptName",
            field: "deptName",
            label: "부서",
            align: "center",
            sortable: true,
          },
          {
            name: "seniorName",
            field: "seniorName",
            label: "법규선해임",
            align: "center",
            sortable: true,
          },
          {
            name: "jobName",
            field: "jobName",
            label: "직책",
            align: "center",
            sortable: true,
          },
          {
            name: "userName",
            field: "userName",
            label: "교육대상자명",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
        height: '350px'
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        userId: '',
        seniorName: '',
        jobCd: null,
        useFlag: 'Y',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      eduSeniorItems: [],
      userNm: '',
      userId: '',
      chkYmd: '',
      editable: true,
      searchUrl: '',
      selectData: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    // 'searchParam.targetClassCd'() {
    // },
  },
  methods: {
    init() {
      // role setting
      this.searchParam.targetClassCd = this.popupParam.targetClassCd
      // url setting
      this.listUrl = selectConfig.sop.edu.user.list.url;
      // code setting
      // list setting
      this.getList();
      this.getSeniorList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getSeniorList() {
      this.$http.url = selectConfig.mdm.senior.list.url;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.eduSeniorItems.push({
            seniorId: _item.seniorId,
            seniorName: _item.seniorName,
          })
        })
      },);
    },
    select() {
      this.selectData = this.$refs['table'].selected;
      if (!this.selectData || this.selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '대상자를 선택하세요.', // 대상자를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', { 
          selectData: this.selectData,
        });
      }
    },
  }
};
</script>
